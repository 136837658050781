import { authGuard } from '@/helpers/AuthGuard.js'

const users = [
  {
    path: '/users',
    components: {
      admin: () => import ('@/views/users'),
    },
    beforeEnter: authGuard
  },
]

export default users