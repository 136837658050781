<template>
  <div class="tooltip tooltip-bottom hidden sm:inline-block" :data-tip="tooltip">
    <button class="btn btn-square ml-auto btn-ghost text-gray-600">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: String
  }
}
</script>