<template>
  <Notifications :items="notifications" :state="state">
    <template v-slot:action>
      <router-link to="/notifications" class="flex items-center text-sm hover:text-gray-600 text-gray-300 border-0 focus:outline-none">
        View All
      </router-link>
    </template>
  </Notifications>
</template>

<script>
import { State } from '@/common/variables'
import { notification } from '@/parse/notification'

export default {
  data () {
    return {
      State,
      state: State.Loading,
      notifications:null
    }
  },
  mounted () {
    notification.recent().then(function (data) {
      this.notifications = data
      if(data.length > 0){
        this.state = State.Done
      }else {
        this.state = State.Empty
      }
    }.bind(this), ()=>{
      this.state = State.Error
    })
  },
  methods: {
    clickM () {
      console.log('ssds')
    },
  }
}
</script>