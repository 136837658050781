import { authGuard } from '@/helpers/AuthGuard.js'

const account = [
  {
    path: '/account',
    name: 'Account',
    redirect: '/account/profile',
    component: () => import ('@/views/account/Account.vue'),
    children: [
      {
        path: 'profile',
        name: 'Account Profile',
        component: () => import ('@/views/account/Profile.vue'),
        beforeEnter: authGuard
      },
      {
        path: 'security',
        name: 'Account Security',
        component: () => import ('@/views/account/Security.vue'),
        beforeEnter: authGuard
      },
      {
        path: 'session/:id',
        name: 'Account Session',
        component: () => import ('@/views/account/Session.vue'),
        beforeEnter: authGuard
      }
    ]
},
]

export default account