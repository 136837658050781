<template>
  <div class="shadow sm:rounded  p-4 pb-0 bg-white relative overflow-hidden">
    <div class="w-full flex items-center justify-between mb-8">
      <p class="text-gray-800 dark:text-white text-xl">
        Forms
      </p>
      <a :href="zipLink" class="flex items-center text-sm hover:text-gray-600 dark:text-gray-50 dark:hover:text-white text-gray-300 border-0 focus:outline-none">
        Download All
      </a>
    </div>
    <div v-if="state==State.Done">
      <template v-for="form in forms" :key="form.filename">
        <div v-if="form.type==type" class="flex items-center mb-6 rounded justify-between">
          <span class="rounded-lg">
            <img src="/img/excel-icon.png" alt="" class=" h-8 w-8">
          </span>
          <a :href="form.link" class="flex items-center w-full justify-between">
            <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
              <p class="dark:text-white">
                {{ form.name }}
              </p>
              <p class="text-gray-300">
                {{ form.filename }}
              </p>
            </div>
          </a>
        </div>
      </template>
    </div>
    <div v-if="state==State.Loading" class="h-72"></div>
  </div>
</template>

<script>
import { State } from '@/common/variables'
import { institution } from '@/parse/institution'

const forms = [
  {
    type: 'private',
    link: "/downloads/nonsuc/00000000_NONSUC-e-Forms-A_2022.xlsx",
    filename: '00000000_NONSUC-e-Forms-A_2022.xlsx',
    name: 'Form A'
  },
  {
    type: 'private',
    link: '/downloads/nonsuc/00000000_NONSUC-e-Forms-B-C_2022.xlsx',
    filename: '00000000_NONSUC-e-Forms-B-C_2022.xlsx',
    name: 'Form BC'
  },
  {
    type: 'private',
    link: '/downloads/nonsuc/00000000_NONSUC-Form-E5-Faculty-Form_2022.xlsx',
    filename: '00000000_NONSUC-Form-E5-Faculty-Form_2022.xlsx',
    name: 'Form E5'
  },
  {
    type: 'private',
    link: '/downloads/nonsuc/00000000_NONSUC-PRC-List-of-Graduates_2022.xlsx',
    filename: '00000000_NONSUC-PRC-List-of-Graduates_2022.xlsx',
    name: 'PRC List of Graduates'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-FORM-A_2022.xlsx',
    filename: '00000000_SUC-NF-FORM-A_2022.xlsx',
    name: 'Form A'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-FORM-B_2022.xlsx',
    filename: '00000000_SUC-NF-FORM-B_2022.xlsx',
    name: 'Form B'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-FORM-E1_2022.xlsx',
    filename: '00000000_SUC-NF-FORM-E1_2022.xlsx',
    name: 'Form E1'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-FORM-E2_2022.xlsx',
    filename: '00000000_SUC-NF-FORM-E2_2022.xlsx',
    name: 'Form E2'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-FORM-GH_2022.xlsx',
    filename: '00000000_SUC-NF-FORM-GH_2022.xlsx',
    name: 'Form GH'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-NF-Research-Extension-Forms_2022.xlsx',
    filename: '00000000_SUC-NF-Research-Extension-Forms_2022.xlsx',
    name: 'Form RE'
  },
  {
    type: 'public',
    link: '/downloads/suc/00000000_SUC-PRC-List-of-Graduates_2022.xlsx',
    filename: '00000000_SUC-PRC-List-of-Graduates_2022.xlsx',
    name: 'PRC List of Graduates'
  },
]

export default {
  data () {
    return {
      State,
      state: State.Loading,
      forms,
      type: null,
      zipLink: null,
    }
  },
  mounted () {
    institution.first().then(function(data){
      const code = data?.get('formOfOwnership')?.get('code')
      this.type = code=='CSCU-MAIN'||code=='CSCU-SAT'?'public':'private'
      // console.log(this.type)
      this.zipLink = this.type == 'private'? '/downloads/nonsuc/NONSUC-e-Forms_2022.zip':'/downloads/suc/SUC-FORMS_2022.zip'
      this.state = State.Done
    }.bind(this))
  }
}
</script>