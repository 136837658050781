<template>
  <div v-if="showNotificationPrompt" class="absolute bottom-0 left-0 sm:bottom-3 sm:left-3 z-50 flex max-w-md bg-white shadow rounded overflow-hidden">
    <div class="p-4">
      <h1 class="text-gray-900 font-bold text-2xl">
        Enable notification?
      </h1>
      <p class="mt-2 text-gray-600 text-sm">
        You currently not allowing CBIS to send you notifications. To receive notifications, click the 'YES' button below, select forever and click allow. Thank you!
      </p>
      <div class="flex item-cente mt-3 justify-end">
        <button class="btn w-1/3 btn-ghost" @click="hidePrompt">
          Close
        </button>
        <button class="btn w-1/3" @click="allow" :class="{loading: state == State.Loading}">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { State } from '@/common/variables' 
import { user } from '@/parse/user'
import { session } from '@/parse/session'

export default {
  data () {
    return {
      State,
      state: State.Initial,
      session: null,
      showNotificationPrompt: false
    }
  },
  mounted () {
    if(user.current()){
      session.current().then(function (data) {
        this.session = data
        if(!data.get('FCMToken')) this.showNotificationPrompt = localStorage.getItem("showNotificationPrompt")==null
      }.bind(this))
    }
  },
  methods: {
    hidePrompt () {
      localStorage.setItem("showNotificationPrompt",false)
      this.showNotificationPrompt = false
    },
    allow () {
      this.state = State.Loading
      try {
        this.$messaging?.getToken({ vapidKey: 'BKGzgEbakjVBJOCo5u3XoOw1Amv_xuGJr1KrzCfYVa9wOu6DxpWrUF7NAZKU0q-I6kQx3LdWfYNfIEptW2B8PXk' }).then(function (currentToken){
          if (currentToken) {
            console.log('client token', currentToken)
            this.session?.set('FCMToken', currentToken)
            this.session?.save()
            localStorage.setItem("showNotificationPrompt",false)
            this.showNotificationPrompt = false
            this.state = State.Done
          } else {
            console.error('No registration token available. Request permission to generate one.');
            this.state = State.Error
          }
        }.bind(this), (error) => {
          console.error(error)
          this.showNotificationPrompt = false
          this.state = State.Error
        })
      } catch (error) {
        console.error(error)
        this.state = State.Error
      }
    }
  }
}
</script>