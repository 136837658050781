<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <img src="img/undraw_Warning_re_eoyh.png" class=" w-48 mx-auto" alt="" srcset="">
        <h1 class="mb-5 text-5xl font-bold">
          Session Invalid
        </h1>
        <p class="mb-5">
          You were logged out automatically, click the button below to sign in again.
        </p>
        <router-link to="/auth/signin" class="btn btn-primary">Sign in here</router-link>
      </div>
    </div>
  </div>
</template>