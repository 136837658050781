import { authGuard } from '@/helpers/AuthGuard.js'

const comments = [
  {
    path: '/comments',
    name: 'Comments',
    component: () =>
        import ('@/views/admin/comments/Comments.vue'),
    beforeEnter: authGuard
  },
]

export default comments