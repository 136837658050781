<template>
  <div class="shadow rounded p-4 bg-white text-gray-700 overflow-hidden">
    <div class="w-full h-full block">
      <div class="w-full space-y-3">
        <div class="flex justify-between items-center">
          <p class="text-gray-700 dark:text-white  text-2xl font-light">
            {{ name }}
          </p>
          <div class="dropdown dropdown-end">
            <div tabindex="0" class="btn btn-ghost btn-square">
              <DotsVerticalIcon class="w-5 h-5"/>
            </div> 
            <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
              <li>
                <a :class="{active:type==1}" @click="changeType(1)">Uploads</a>
              </li> 
              <li>
                <a :class="{active:type==2}" @click="changeType(2)">Approved</a>
              </li> 
              <li>
                <a :class="{active:type==3}" @click="changeType(3)">Declined</a>
              </li>
              <li>
                <a :class="{active:type==4}" @click="changeType(4)">No Uploads</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-for="(item, index) in uploads" :key="index">
          <div class="flex items-center justify-between text-gray-400 text-sm">
            <p>{{ item.name }}</p>
            <p v-if="type==1">{{ item.count }}/{{ total }}</p>
            <p v-else-if="type==2">{{ item.approved }}/{{ total }}</p>
            <p v-else-if="type==3">{{ item.count-item.approved }}/{{ total }}</p>
            <p v-else-if="type==4">{{ total - item.count }}/{{ total }}</p>
          </div>
          <progress v-if="type==1" class="progress progress-primary" :value="(item.count/total)*100" max="100"></progress>
          <progress v-else-if="type==2" class="progress progress-success" :value="(item.approved/total)*100" max="100"></progress>
          <progress v-else-if="type==3" class="progress progress-error" :value="((item.count-item.approved)/total)*100" max="100"></progress>
          <progress v-else-if="type==4" class="progress" :value="((total - item.count)/total)*100" max="100"></progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DotsVerticalIcon } from '@heroicons/vue/solid'

export default {
  props: {
    name: String,
    uploads: Array,
    total: Number
  },
  data () {
    return {
      type: 1, // 1 uploads, 2 approved, 3 declined
    }
  },
  components: {
    DotsVerticalIcon
  },
  methods: {
    changeType (type) {
      this.type = type
    }
  }
}
</script>
