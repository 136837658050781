import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: false,
    profileChanged: false,
    userChanged: false,
    offline: false,
    accountType: null,
    institutionType: null,
    role: '',
    config: null,
    academicYearId: ''
  },
  mutations: {
    signIn (state) {
      state.isLoggedIn = true
    },
    signOut (state) {
      state.isLoggedIn = false
    },
    offline (state, status) {
      state.offline = status
    },
    profileChanged (state) {
      state.profileChanged = true
    },
    profileUpdated (state) {
      state.profileChanged = false
    },
    userChanged (state) {
      state.userChanged = true
    },
    userUpdated (state) {
      state.userChanged = false
    },
    setRole (state, role) {
      state.role = role
    },
    setConfig (state, config) {
      state.config = config
    },
    setAcademicYearId (state, id) {
      state.academicYearId = id
    }
  },
  actions: {
  },
  modules: {
  }
})