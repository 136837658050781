import Parse from 'parse'
import { ErrorHandler } from '@/helpers/ErrorHandler'

var user = {
  cloud: {
    list: (args = {}) => {
      return new Parse.Cloud.run('users', args)
    },
    get: (id) => {
      return new Parse.Cloud.run('users', {id:id, keyword:''})
    },
    save: (args = {}) => {
      return Parse.Cloud.run('createUser', args)
    }
  },
  pointer (id) {
    const object = new Parse.User()
    object.id = id
    return object
  },
  login (args = {}) {
    const { email, pass } = args
    if(!pass) throw {message: 'Password is required'}
    if(!email) throw {message: 'Email is required'}

    return Parse.User.logIn(email, pass)
  },
  current () {
    const currentUser = Parse.User.current()
    return currentUser
  },
  getRole: async function () {
    const currentUser = Parse.User.current()
    const q = await new Parse.Query(Parse.Role).equalTo('users', currentUser).first()
    return q
  },
  updateSession (args = {}) {
    const { token, deviceInfo,ipAddress } = args
    Parse.Session.current().then(function(session) {
      session.set("FCMToken", token)
      session.set("deviceInfo", deviceInfo)
      session.set("ipAddress", ipAddress)
      session.save().then(function() {
        console.log('Token registered')
      });
    });
  },
  async save (args = {}) {
    const currentUser = Parse.User.current()
    const { username, fname, lname, avatar, email } = args
    let parseAvatar = null
    
    if(avatar){
      const image = avatar[0]
      parseAvatar = new Parse.File(image.name, image, image.type)
      await parseAvatar.save()
    }

    if(parseAvatar) currentUser.set('avatar', parseAvatar)
    currentUser.set('username', username)
    currentUser.set('fname', fname)
    currentUser.set('lname', lname)
    if(currentUser?.get('email')!=email) currentUser.set('email', email)
    return currentUser.save()
  },
  updatePassword (args = {}) {
    const currentUser = Parse.User.current()
    const { password } = args

    currentUser.setPassword(password)
    return currentUser.save()
  },
  saveSettings (args = {}) {
    const currentUser = Parse.User.current()
    const { settings } = args

    currentUser.set('settings', settings)
    return currentUser.save()
  },
  requestPasswordRequest () {
    const currentUser = Parse.User.current()
    return Parse.User.requestPasswordReset(currentUser.getEmail())
  }
}

export { user, ErrorHandler }