<template>
  <div class="navbar bg-primary text-neutral-content no-print">
    <div class="flex-none flex truncate overflow-ellipsis" :class="{'lg:hidden':!hiddenSidebar}">
      <label for="my-drawer-2" class="btn btn-square drawer-button btn-ghost">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </label>
      <h2 class="text-lg leading-7 lg:hidden">
        {{ $route.name }}
      </h2>
    </div>
    <div class="navbar-end ml-auto space-x-2">
      <div v-if="academicYear" class="dropdown dropdown-end relative">
        <button tabindex="0" class="btn btn-primary">AY {{academicYear.get('yearStart')}}-{{academicYear.get('yearEnd')}}</button>
        <ul tabindex="0" class="p-2 mt-3 shadow menu dropdown-content bg-base-100 text-gray-700 rounded-box w-52 top-10">
          <template v-for="ay in academicYears" :key="ay.id">
            <li v-if="ay.id != academicYearId">
              <a href="#" @click="changeAY(ay)">{{ay.get('yearStart')}}-{{ay.get('yearEnd')}}</a>
            </li>
            <li v-else>
              <a href="#">{{ay.get('yearStart')}}-{{ay.get('yearEnd')}} <span class="badge">current</span> </a>
            </li>
          </template>
        </ul>
      </div>
      <div class="dropdown dropdown-end relative">
        <Avatar tabindex="0" class="w-10 h-10 cursor-pointer" :source="me?.get('avatar')?.url()"/>
        <ul tabindex="0" class="p-2 mt-1 shadow menu dropdown-content bg-base-100 text-gray-700 rounded-box w-52 top-10">
          <li>
            <router-link :to="{name: 'Account'}">Account</router-link>
          </li>
          <li>
            <router-link to="/auth/signout">Logout</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { user } from '@/parse/user'
import { academicYear } from '@/parse/academicYear'
import { config } from '@/parse/config'

export default {
  data () {
    return {
      hiddenSidebar: false,
      me: null,
      academicYear: null,
      academicYearId: null,
      academicYears: []
    }
  },
  mounted() {
    this.hiddenSidebar = localStorage.getItem('hiddenSidebar')
    this.me = user.current()

    console.log(this.$store.state?.academicYearId)

    config.get().then((data) => {
      if(data.get('maintenanceMode')) this.$router.push({name: 'Under Maintenance'})
      if (!this.$store.state?.academicYearId) {
        this.$store.commit('setAcademicYearId', data.get('academicYearId'))
        this.academicYearId = data.get('academicYearId')
      } else {
        this.academicYearId = this.$store.state?.academicYearId
      }
      
      academicYear.latest().then((data)=>{
        this.academicYears = data
        this.academicYear = data.find(o => o.id === this.academicYearId)
      })
    })    
  },
  methods:{
    changeAY (ay) {
      this.academicYearId = ay.id
      this.academicYear = this.academicYears.find(o => o.id === this.academicYearId)
      this.$store.commit('setAcademicYearId', ay.id)
    }
  }
}
</script>