import { authGuard } from '@/helpers/AuthGuard.js'

const records = [
  {
    path: '/records',
    components: {
      admin: () => import ('@/views/admin/records'),
      ched: () => import ('@/views/admin/records'),
      viewOnly: () => import ('@/views/viewOnly/records'),
      hei: () => import ('@/views/records/Records.vue'),
    },
    children: [
			{
				path: 'profile',
				name: 'Institution Profile',
				component: () => import ('@/views/records/profile/Profile.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'profile/edit',
				name: 'Edit Institution Profile',
				component: () => import ('@/views/records/profile/Edit.vue'),
				beforeEnter: authGuard
			},
      {
        path: 'programs',
        name: 'Records of Programs',
        redirect: '/records/programs/list',
        components: {
          // hei: () => import ('@/views/records/programs/Programs.vue'),
          admin: () => import ('@/views/admin/records/programs'),
          viewOnly: () => import ('@/views/admin/records/programs'),
        },
        beforeEnter: authGuard,
        children: [
          {
            path: 'list',
            name: 'Program List',
            component: () => import ('@/views/admin/records/programs/list'),
            beforeEnter: authGuard
          },
          {
            path: 'summary',
            name: 'Program Summary',
            component: () => import ('@/views/admin/records/programs/summary.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'province',
            name: 'Programs by Province',
            component: () => import ('@/views/admin/records/programs/province.vue'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'heis',
        name: 'Records of HEIs',
        redirect: '/records/heis/directory',
        component: () => import ('@/views/admin/records/heis'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'directory',
            name: 'HEI Directory',
            component: () => import ('@/views/admin/records/heis/directory'),
            beforeEnter: authGuard
          },
          {
            path: 'distribution',
            name: 'HEI Distribution',
            component: () => import ('@/views/admin/records/heis/distribution'),
            beforeEnter: authGuard
          },
          {
            path: 'graduates',
            name: 'HEI Graduates',
            component: () => import ('@/views/admin/records/heis/graduates'),
            beforeEnter: authGuard
          },
          {
            path: 'enrollees',
            name: 'HEI Enrollees',
            component: () => import ('@/views/admin/records/heis/enrollees'),
            beforeEnter: authGuard
          },
          {
            path: 'faculties',
            name: 'HEI Faculties',
            component: () => import ('@/views/admin/records/heis/faculties'),
            beforeEnter: authGuard
          },
          {
            path: 'map',
            name: 'HEI Map',
            component: () => import ('@/views/admin/records/heis/map'),
            beforeEnter: authGuard
          },
          {
            path: 'users',
            name: 'HEI Users',
            component: () => import ('@/views/admin/records/heis/users'),
            beforeEnter: authGuard
          },
          {
            path: 'profile/:id',
            name: 'HEI Profile',
            component: () => import ('@/views/admin/records/heis/profile'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'enrollees',
        name: 'Records of Enrollees',
        redirect: '/records/enrollees/province',
        component: () => import ('@/views/admin/records/enrollees'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'province',
            name: 'Enrollees by Province',
            component: () => import ('@/views/admin/records/enrollees/province.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'discipline',
            name: 'Enrollees by Discipline',
            component: () => import ('@/views/admin/records/enrollees/discipline.vue'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'faculties',
        name: 'Records of Faculties',
        redirect: '/records/faculties/province',
        component: () => import ('@/views/admin/records/faculties'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'list',
            name: 'Faculty List',
            component: () => import ('@/views/admin/records/faculties/list'),
            beforeEnter: authGuard
          },
          {
            path: 'province',
            name: 'Faculties by Province',
            component: () => import ('@/views/admin/records/faculties/province.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'level',
            name: 'Faculties by Level',
            component: () => import ('@/views/admin/records/faculties/level.vue'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'submissions',
        name: 'Records of Submissions',
        component: () => import ('@/views/admin/records/submissions'),
        beforeEnter: authGuard,
      },
      {
        path: 'graduates',
        name: 'Records of Graduates',
        redirect: '/records/graduates/province',
        component: () => import ('@/views/admin/records/graduates'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'province',
            name: 'Graduates by Province',
            component: () => import ('@/views/admin/records/graduates/province.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'discipline',
            name: 'Graduates by Discipline',
            component: () => import ('@/views/admin/records/graduates/discipline.vue'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'disciplines',
        name: 'Records of Disciplines',
        redirect: '/records/disciplines/province',
        component: () => import ('@/views/admin/records/disciplines'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'province',
            name: 'Disciplines by Province',
            component: () => import ('@/views/admin/records/disciplines/province.vue'),
            beforeEnter: authGuard
          },
        ]
      },
    ]
  },
]

export default records