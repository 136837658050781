<template>
  <div class="text-sm breadcrumbs px-6 py-4 sm:inline-block no-scrollbar h-12 overflow-scroll">
    <ul>
      <li v-for="link in links" :key="link">
        <router-link :to="link.path">{{link.name}}</router-link>
      </li>
      <li>{{ name }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    links: Array
  }
}
</script>