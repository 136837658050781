<template>
  <section class="bg-white ">
    <div class="container flex items-center min-h-screen px-6 py-12 mx-auto">
      <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-primary rounded-full bg-blue-50 ">
          <ViewGridAddIcon class="w-7 h-7"/>
        </p>
        <h1 class="mt-3 text-2xl font-semibold text-gray-800  md:text-3xl">Under Construction</h1>
        <p class="mt-4 text-gray-500 ">This page is under construction.<br> Click the link below for the data reports:</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
          <router-link to="/records" class="btn btn-ghost">
            <DocumentReportIcon class="w-6 h-6 mr-3"/>
            <span>Records</span>
          </router-link>

          <!-- <router-link to="/records" class="btn">
            Records
          </router-link> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ViewGridAddIcon,DocumentReportIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ViewGridAddIcon,
    DocumentReportIcon
  }
}
</script>