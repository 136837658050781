import { authGuard } from '@/helpers/AuthGuard.js'

const programs = [
  {
    path: '/programs',
    redirect: '/programs/manage',
    components: {
      admin: () => import ('@/views/programs/admin'),
      hei: () => import ('@/views/programs/hei'),
    },
    children: [
      {
				path: 'manage',
				name: 'Manage Programs',
        components: {
          admin: () => import ('@/views/programs/admin/manage'),
          hei: () => import ('@/views/programs/hei/manage'),
        },
				beforeEnter: authGuard
			},
      {
				path: 'mapping',
				name: 'Mapping of Programs',
        components: {
          admin: () => import ('@/views/programs/admin/mapping'),
        },
				beforeEnter: authGuard
			},
    ]
  },
]

export default programs