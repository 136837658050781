<template>
  <div class="shadow sm:rounded p-4 pb-6 bg-white relative overflow-hidden flex flex-col">
    <div class="w-full flex items-center justify-between mb-8">
      <p class="text-gray-800 dark:text-white text-xl font-normal">
        Notifications
      </p>
      <slot name="action"></slot>
    </div>
    <template v-if="state==State.Done">
      <div v-for="(item, index) in items" :key="index" class="flex items-start mb-6 last:mb-0 rounded justify-between">
        <span v-if="item.get('type')=='document'" class="rounded-full text-white p-2 bg-green-300">
          <DocumentIcon class="h-5 w-5"/>
        </span>
        <span v-if="item.get('type')=='announcement'" class="rounded-full text-white p-2 bg-yellow-300">
          <BellIcon class="h-5 w-5"/>
        </span>
        <span v-if="item.get('type')=='comment'" class="rounded-full text-white p-2 bg-blue-300">
          <AnnotationIcon class="h-5 w-5"/>
        </span>
        <span v-if="item.get('type')=='notice'" class="rounded-full text-white p-2 bg-red-300">
          <ExclamationIcon class="h-5 w-5"/>
        </span>
        <div class="flex items-center w-full justify-between">
          <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
            <p class="text-gray-700 dark:text-white" v-html="sanitizeHtml(marked(item.get('title')))"></p>
            <p class="text-gray-300">
              {{ moment(item.get('createdAt'))?.fromNow() }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <div v-if="state==State.Loading">
      <div class="flex items-start mb-6 last:mb-0 rounded justify-between animate-pulse">
        <span class="rounded-full p-2 bg-gray-200"> <div class="h-5 w-5"></div> </span>
        <div class="flex items-center w-full justify-between">
          <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
            <div class="bg-gray-500 dark:text-white h-4 w-32 rounded"></div>
            <div class="bg-gray-200 h-4 w-12 mt-1 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { State } from '@/common/variables'
import moment from 'moment'
import marked from 'marked'
import sanitizeHtml from 'sanitize-html'
import { DocumentIcon, BellIcon, AnnotationIcon, ExclamationIcon } from '@heroicons/vue/outline'

export default {
  props: {
    items: Array,
    state: Number
  },
  data () {
    return {
      State
    }
  },
  components: {
    DocumentIcon,
    BellIcon,
    AnnotationIcon,
    ExclamationIcon
  },
  mounted () {
    // const html = 
    // console.log(html)
    // console.log(sanitizeHtml(html))
  },
  created: function () {
    this.moment = moment
    this.marked = marked
    this.sanitizeHtml = sanitizeHtml
  }
}
</script>