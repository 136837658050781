import { authGuard } from '@/helpers/AuthGuard.js'

const notifications = [
  {
    path: '/notifications',
    name: 'Notifications',
    component: () =>
        import ('@/views/notifications/Notifications.vue'),
    beforeEnter: authGuard
  },
]

export default notifications