import Parse from 'parse'
import { ErrorHandler } from '@/helpers/ErrorHandler'

const AcademicYear = Parse.Object.extend('AcademicYear')

var academicYear = {
  all: function () {
    const query = new Parse.Query(AcademicYear)
    query.descending('yearEnd')
    return query.find()
  },
  latest: function () {
    const query = new Parse.Query(AcademicYear)
    query.descending('yearEnd')
    query.limit(5)
    return query.find()
  },
  pointer: function (id) {
    const query = new AcademicYear()
    query.id = id
    return query
  }
}

export { academicYear, ErrorHandler }