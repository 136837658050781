import { createRouter, createWebHistory } from 'vue-router'

import auth from './routes/auth'
import account from './routes/account'
import settings from './routes/settings'
import dataCollection from './routes/dataCollection'
import reports from './routes/reports'
import references from './routes/references'
import notifications from './routes/notifications'
import comments from './routes/comments'
import uploads from './routes/uploads'
import forum from './routes/forum'
import monitoring from './routes/monitoring'
import records from './routes/records'
import upload from './routes/upload'
import users from './routes/users'
import dashboard from './routes/dashboard'
import programs from './routes/programs'
import { authGuard } from '@/helpers/AuthGuard.js'


const routes = [
  {
      path: '/institutions',
      name: 'Institutions',
      redirect: '/institutions/lists',
      component: () => import ('../views/admin/institutions/Institutions.vue'),
      children: [
        {
          path: 'lists',
          name: 'Lists of Institutions',
          component: () =>
              import ('../views/admin/institutions/Lists.vue'),
          beforeEnter: authGuard
        },
        {
          path: 'import',
          name: 'Import Institutions',
          component: () =>
              import ('../views/admin/institutions/Import.vue'),
          beforeEnter: authGuard
        },
        {
          path: 'map',
          name: 'Map of Institutions',
          component: () =>
              import ('../views/admin/institutions/Map.vue'),
          beforeEnter: authGuard
        },
        {
          path: 'representatives',
          name: 'Representatives of Institutions',
          component: () =>
              import ('../views/admin/institutions/representatives/Representatives.vue'),
          beforeEnter: authGuard
        },
        {
          path: 'representatives/import',
          name: 'Import Representatives',
          component: () =>
              import ('../views/admin/institutions/representatives/Import.vue'),
          beforeEnter: authGuard
        },
        {
          path: 'add',
          name: 'Add Institution',
          component: () =>
              import ('../views/admin/institutions/Add.vue'),
          beforeEnter: authGuard
        },
      ]
    },
]

routes.push(...auth)
routes.push(...account)
routes.push(...settings)
routes.push(...dataCollection)
routes.push(...reports)
routes.push(...notifications)
routes.push(...comments)
routes.push(...references)
routes.push(...uploads)
routes.push(...users)
routes.push(...records)
routes.push(...monitoring)
routes.push(...upload)
routes.push(...forum)
routes.push(...dashboard)
routes.push(...programs)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router