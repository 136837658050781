import { authGuard } from '@/helpers/AuthGuard.js'

const uploads = [
  {
    path: '/uploads',
    name: 'Uploads',
    redirect: '/uploads/list',
    component: () => import ('@/views/admin/uploads/Uploads.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'list',
        name: 'Lists of Uploads',
        component: () => import ('@/views/admin/uploads/list'),
        beforeEnter: authGuard
      },
      {
        path: 'view/:id',
        name: 'View Upload',
        component: () => import ('@/views/admin/uploads/View.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'form-a',
            name: 'View Form A',
            components: {
              private: () => import ('@/views/admin/uploads/view/private/FormA.vue'),
              public: () => import ('@/views/admin/uploads/view/public/FormA.vue')
            },
            beforeEnter: authGuard
          },
          {
            path: 'form-b',
            name: 'View Form B',
            component: () => import ('@/views/admin/uploads/view/public/FormB.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-b-c',
            name: 'View Form B/C',
            component: () => import ('@/views/admin/uploads/view/private/FormBC.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e1',
            name: 'View Form E1',
            component: () => import ('@/views/admin/uploads/view/public/FormE1.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e2',
            name: 'View Form E2',
            component: () => import ('@/views/admin/uploads/view/public/FormE2.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e5',
            name: 'View Form E5 Faculty Form',
            component: () => import ('@/views/admin/uploads/view/private/FormE5.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-gh',
            name: 'View Form GH',
            component: () => import ('@/views/admin/uploads/view/public/FormGH.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'prc-list-of-graduates',
            name: 'View PRC List of Graduates',
            components: {
              private: () => import ('@/views/admin/uploads/view/private/FormGraduates.vue'),
              public: () => import ('@/views/admin/uploads/view/public/FormGraduates.vue')
            },
            beforeEnter: authGuard
          },
          {
            path: 'research-extension-form',
            name: 'View Research Extension Form',
            component: () => import ('@/views/admin/uploads/view/public/FormRE.vue'),
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: 'spreadsheet/:id',
        name: 'Spreadsheet',
        component: () => import ('@/views/admin/uploads/spreadsheet'),
      },
      {
        path: 'import/:id',
        name: 'Import Upload',
        component: () => import ('@/views/admin/uploads/import'),
        children: [
          {
            path: 'form-a',
            name: 'Import Form A SUC',
            component: () => import ('@/views/admin/uploads/import/public/formA.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-a',
            name: 'Import Form A NONSUC',
            component: () => import ('@/views/admin/uploads/import/private/formA.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'prc-list-of-graduates',
            name: 'Import Form List of Graduates SUC',
            component: () => import ('@/views/admin/uploads/import/public/formGraduates.vue'), 
            beforeEnter: authGuard
          },
          {
            path: 'prc-list-of-graduates',
            name: 'Import Form List of Graduates NONSUC',
            component: () => import ('@/views/admin/uploads/import/private/formGraduates.vue'), 
            beforeEnter: authGuard
          },
          {
            path: 'form-b',
            name: 'Import Form B SUC',
            component: () => import ('@/views/admin/uploads/import/public/formB.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-b-c',
            name: 'Import Form B/C NONSUC',
            component: () => import ('@/views/admin/uploads/import/private/formBC.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e1',
            name: 'Import Form E1 SUC',
            component: () => import ('@/views/admin/uploads/import/public/formE1.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e2',
            name: 'Import Form E2 SUC',
            component: () => import ('@/views/admin/uploads/import/public/formE2.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-e5',
            name: 'Import Form E5 NONSUC',
            component: () => import ('@/views/admin/uploads/import/private/formE5.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'form-gh',
            name: 'Import Form GH SUC',
            component: () => import ('@/views/admin/uploads/import/public/formGH.vue'),
            beforeEnter: authGuard
          },
          {
            path: 'research-extension-form',
            name: 'Import Form RE SUC',
            component: () => import ('@/views/admin/uploads/import/public/formRE.vue'),
            beforeEnter: authGuard
          },
        ]
      },
    ]
  },
]

export default uploads