import Parse from 'parse'
import SignIn from '@/views/auth/SignIn.vue'
import SignUp from '@/views/auth/SignUp.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import NotFound from '@/views/error/NotFound.vue'
import SessionInvalid from '@/views/error/SessionInvalid.vue'
import UnderMaintenance from '@/views/error/underMaintenance.vue'

const auth = [
  {
    path: '/auth/signin',
    name: 'Sign In',
    // component: () => import('@/views/auth/SignIn.vue')
    component: SignIn
  },
  {
    path: '/auth/signup',
    name: 'Sign Up',
    // component: () => import('@/views/auth/SignUp.vue')
    component: SignUp
  },
  {
    path: '/auth/verify-email',
    name: 'Verify Email',
    component: () =>
        import ('@/views/auth/VerifyEmail.vue'),
  },
  {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    // component: () => import('@/views/auth/ForgotPassword.vue')
  },
  {
    path: '/auth/signout',
    component: {
      beforeRouteEnter(to, from, next) {
        Parse.User.logOut()
        localStorage.clear()
        const destination = {
          path: '/auth/signin',
          query: from.query,
          params: from.params
        }
        next(destination)
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
    // component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '/session-invalid',
    name: 'Session Invalid',
    component: SessionInvalid,
  },
  {
    path: '/under-maintenance',
    name: 'Under Maintenance',
    component: UnderMaintenance,
  }
]

export default auth