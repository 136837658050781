<template>
  <div class="block absolute bottom-2 right-2">
    <div class="dropdown dropdown-top dropdown-end">
      <button class="btn btn-circle btn-primary m-1" @click="action">
        <slot name="icon"></slot>
      </button>
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    action () {
      this.$emit('action')
    }
  }
}
</script>