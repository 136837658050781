import { authGuard } from '@/helpers/AuthGuard.js'

const reports = [
  {
    path: '/reports',
    name: 'Reports',
    component: () =>
        import ('@/views/reports/Reports.vue'),
    beforeEnter: authGuard
  },
]

export default reports