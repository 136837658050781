import Parse from 'parse'

const Upload = Parse.Object.extend('Upload')
const AcademicYear = Parse.Object.extend('AcademicYear')

var file = {
  upload: ( args = {}) => {
    const { name, file } = args
    let parseFile = new Parse.File(name, file)
    return parseFile.save()
  },
  find: ( args = {} ) => {
    const { academicYearId } = args
    // console.log(args)
    const academicYear = new AcademicYear()
    academicYear.id = academicYearId

    const query = new Parse.Query(Upload)
    query.equalTo('academicYear', academicYear)
    query.include('form')
    query.ascending('createdAt')
    return query.find()
  },
  get: (id) => {
    const query = new Parse.Query(Upload)
    query.include('form')
    query.include('institution')
    return query.get(id)
  },
  save: async ( args = {} ) => {
    const { id, file } = args
    // console.log(file)

    let parseFile = new Parse.File(file.name, file)
    await parseFile.save()

    if(!parseFile) throw new Error({file: 'File is required'} )
    const object = new Upload()
    object.id = id
    object.set('filename', file.name)
    object.set('file', parseFile)
    return object.save()
  },
  approve: (id) => {
    const object = new Upload()
    const user = Parse.User.current()
    object.id = id
    object.set('notice', false)
    object.set('approved', true)
    object.set('approvedAt', new Date())
    object.set('approvedBy', user)
    return object.save()
  },
  decline: (id) => {
    const object = new Upload()
    const user = Parse.User.current()
    object.id = id
    object.set('notice', true)
    object.set('approved', false)
    object.set('declinedAt', new Date())
    object.set('declinedBy', user)
    return object.save()
  },
  revert: (id) => {
    const object = new Upload()
    object.id = id
    object.set('notice', false)
    object.set('approved', false)
    return object.save()
  },
  checked: (id) => {
    const object = new Upload()
    const user = Parse.User.current()
    object.id = id
    object.set('checkedAt', new Date())
    object.set('checkedBy', user)
    return object.save()
  },
}

export { file }