import Parse from 'parse'

var functions = {
  getClientIP: () => {
    return Parse.Cloud.run('getClientIP')
  },
  getClientInfo: () => {
    return Parse.Cloud.run('getClientInfo')
  }
}

export { functions }