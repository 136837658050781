import { authGuard } from '@/helpers/AuthGuard.js'

const upload = [
  {
    path: '/upload',
    redirect: '/upload/files',
    component: () => import ('@/views/hei/upload/Upload.vue'),
    children: [
      {
				path: 'files',
				name: 'Upload',
				component: () => import ('@/views/hei/upload/Main.vue'),
				beforeEnter: authGuard
			},
			{
				path: 'form-a/:id',
				name: 'Form A',
				components: {
          private: () => import ('@/views/hei/upload/forms/private/FormA.vue'),
          public: () => import ('@/views/hei/upload/forms/public/FormA.vue')
        },
				beforeEnter: authGuard
			},
      {
				path: 'form-b/:id',
				name: 'Form B',
				component: () => import ('@/views/hei/upload/forms/public/FormB.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'form-b-c/:id',
				name: 'Form B/C',
				component: () => import ('@/views/hei/upload/forms/private/FormBC.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'form-e1/:id',
				name: 'Form E1',
				component: () => import ('@/views/hei/upload/forms/public/FormE1.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'form-e2/:id',
				name: 'Form E2',
				component: () => import ('@/views/hei/upload/forms/public/FormE2.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'form-e5/:id',
				name: 'Form E5',
				component: () => import ('@/views/hei/upload/forms/private/FormE5.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'form-gh/:id',
				name: 'Form GH',
				component: () => import ('@/views/hei/upload/forms/public/FormGH.vue'),
				beforeEnter: authGuard
			},
      {
				path: 'prc-list-of-graduates/:id',
				name: 'Form List of Graduates',
				components: {
          private: () => import ('@/views/hei/upload/forms/private/FormGraduates.vue'),
          public: () => import ('@/views/hei/upload/forms/public/FormGraduates.vue')
        },
				beforeEnter: authGuard
			},
      {
				path: 'research-extension-form/:id',
				name: 'Form RE',
				component: () => import ('@/views/hei/upload/forms/public/FormRE.vue'),
				beforeEnter: authGuard
			},
    ]
  },
]

export default upload