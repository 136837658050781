<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <img src="img/undraw_Taken_re_yn20.png" class=" w-48 mx-auto" alt="" srcset="">
        <h1 class="mb-5 text-5xl font-bold">
          Under Maintenance
        </h1>
        <p class="mb-5">
          Sorry for the inconvenience. We are doing a system upgrade to serve you better. Thank you for understanding.
        </p>
      </div>
    </div>
  </div>
</template>