import { authGuard } from '@/helpers/AuthGuard.js'

const monitoring = [
  {
    path: '/monitoring',
    components: {
      ched: () => import ('@/views/monitoring/ched'),
      admin: () => import ('@/views/monitoring/admin'),
    },
    beforeEnter: authGuard
  },
]

export default monitoring