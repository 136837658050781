import { authGuard } from '@/helpers/AuthGuard.js'

const dataCollection = [
  {
    path: '/data-collection',
    redirect: '/data-collection/face-to-face',
    components: {
      hei: () => import ('@/views/hei/dataColllection'),
      admin: () => import ('@/views/ched/dataColllection'),
      ched: () => import ('@/views/ched/dataColllection'),
    },
    children: [
      {
				path: 'face-to-face',
				name: 'Limited Face-to-face Classes',
        components: {
          hei: () => import ('@/views/hei/dataColllection/face2face'),
          admin: () => import ('@/views/ched/dataColllection/face2face'),
          ched: () => import ('@/views/ched/dataColllection/face2face'),
        },
				beforeEnter: authGuard
			},
      {
				path: 'vaccination',
				name: 'Vaccination',
        components: {
          hei: () => import ('@/views/hei/dataColllection/vaccination'),
          admin: () => import ('@/views/ched/dataColllection/vaccination'),
          ched: () => import ('@/views/ched/dataColllection/vaccination'),
        },
				beforeEnter: authGuard
			},
      {
				path: 'vaccination/add',
				name: 'Add Vaccination',
        components: {
          hei: () => import ('@/views/hei/dataColllection/vaccination/view'),
          // admin: () => import ('@/views/ched/dataColllection/vaccination'),
          // ched: () => import ('@/views/ched/dataColllection/vaccination'),
        },
				beforeEnter: authGuard
			},
      {
				path: 'vaccination/view/:id',
				name: 'View Vaccination',
        components: {
          hei: () => import ('@/views/hei/dataColllection/vaccination/view'),
          // admin: () => import ('@/views/ched/dataColllection/vaccination'),
          // ched: () => import ('@/views/ched/dataColllection/vaccination'),
        },
				beforeEnter: authGuard
			},
    ]
  },
]

export default dataCollection