import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Toaster from '@meforma/vue-toaster'

import firebaseMessaging from '@/common/firebase';

import Navbar from '@/components/Navbar'
import Avatar from '@/components/Avatar'
import Sidebar from '@/components/Sidebar'
import Container from '@/components/Container'
import Notifications from '@/components/Notifications'
import FloatingActionButton from '@/components/FloatingActionButton'

import TableTemplate from '@/components/table'
import THead from '@/components/table/thead.vue'

import BtnSquare from '@/components/button/Square'
import BtnPrint from '@/components/button/print.vue'

import SpinnerIcon from '@/components/icon/spinner.vue'

import Breadcrumbs from '@/components/Breadcrumbs.vue'

import PerfectScrollbar from 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.esm'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)
  .component('Avatar', Avatar)
  .component('Navbar', Navbar)
  .component('Sidebar', Sidebar)
  .component('Container', Container)
  .component('Notifications', Notifications)
  .component('FAB', FloatingActionButton)
  .component('TableTemplate', TableTemplate)
  .component('THead', THead)
  .component('BtnSquare', BtnSquare)
  .component('BtnPrint', BtnPrint)
  .component('SpinnerIcon', SpinnerIcon)
  .component('Breadcrumbs', Breadcrumbs)
  .use(PerfectScrollbar)
  .use(Toaster)
  .use(store).use(router)

app.config.globalProperties.$messaging = firebaseMessaging
app.config.productionTip = false

app.mount('#app')