import Parse from 'parse'

var session = {
  find: () => {
    const q = new Parse.Query(Parse.Session)
    q.descending('createdAt')
    return q.find()
  },
  get: ( id ) => {
    if(!id) throw "ID is required"
    const q = new Parse.Query(Parse.Session)
    return q.get(id)
  },
  current: () => {
    return Parse.Session.current()
  }
}

export { session }