import { authGuard } from '@/helpers/AuthGuard.js'

const settings = [
  {
    path: '/settings',
    name: 'Settings',
    redirect: '/settings/personalize',
    component: () => import ('@/views/settings/Settings.vue'),
    children: [
      {
        path: 'personalize',
        name: 'Personalize Settings',
        component: () =>
          import ('@/views/settings/Personalize.vue'),
        beforeEnter: authGuard
      },
      {
        path: 'notification',
        name: 'Notification Settings',
        component: () =>
          import ('@/views/settings/Notification.vue'),
        beforeEnter: authGuard
      },
      {
        path: 'sync',
        name: 'Sync Settings',
        component: () =>
          import ('@/views/settings/Sync.vue'),
        beforeEnter: authGuard
      },
    ]
  },
]

export default settings