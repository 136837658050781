<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <img src="img/undraw_Walking_outside_re_56xo.png" class=" w-48 mx-auto" alt="" srcset="">
        <h1 class="mb-5 text-5xl font-bold">
          Not Found
        </h1>
        <p class="mb-5">
          Looks like you were lost eh?
        </p>
        <router-link to="/" class="btn btn-primary">Go Home</router-link>
      </div>
    </div>
  </div>
</template>