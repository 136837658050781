import { authGuard } from '@/helpers/AuthGuard.js'

const references = [
  {
    path: '/references',
    name: 'References',
    redirect: '/references/discipline-codes',
    component: () =>
        import ('@/views/references/References.vue'),
    children: [
      {
        path: 'discipline-codes',
        name: 'Discipline Codes',
        components: {
          hei: () => import ('@/views/references/discipline/hei'),
          admin: () => import ('@/views/references/discipline/admin'),
          ched: () => import ('@/views/references/discipline/ched'),
        },
        beforeEnter: authGuard
      }
    ]
  },
]

export default references