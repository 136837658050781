import router from '../router'
import store from '../store'
import Parse from 'parse'

export function ErrorHandler (err) {
  console.log('Error Handler: ', err.code)
  switch (err.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      console.log('Invalid Session')
      Parse.User.logOut()
      router.push({ name: 'Session Invalid' })
      console.log('Redirect')
      break
    case Parse.Error.CONNECTION_FAILED:
      store.commit('updateConnectionStatus', true)
      break
  }
}