<template>
  <label class="btn btn-ghost" @click="openModal()"><UploadIcon class="h-5 w-5 mr-2"/>Import</label>
  <input type="checkbox" id="import-modal" class="modal-toggle" v-model="open"> 
  <div class="modal">
    <div class="modal-box">
      <template v-if="json.length == 0">
        <div  class="p-12 border-2 border-dashed rounded-md text-center h-52 content-center flex" @dragover="dragOver"  @drop="dropFile">
          <input type="file" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept="" />
          <div class="my-auto mx-auto">
            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div>
                You can drag and drop your a here
                or <span class="underline">click here</span> to upload a file.
              </div>
            </label>
            <div v-if="file" class="mt-4">
              <span class="text-sm p-1">
                {{ file.name }}<button class="ml-2 btn btn-ghost btn-xs" type="button" @click="removeFile()" title="Remove file">remove</button>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <button @click="template()" class="btn btn-outline mr-auto"><DownloadIcon class="h-5 w-5 mr-2"/>Template</button>
          <label for="import-modal" class="btn btn-ghost">Cancel</label> 
        </div>
      </template>
      <template v-else>
        <div class="h-52">
          <perfect-scrollbar class="h-full">
            <table class="divide-y divide-gray-200 mb-3 border-b w-full h-full">
              <thead>
                <template v-for="(column, index) in columns" :key="index">
                  <template v-if="column?.import == null || column?.import">
                    <th scope="col" class="px-2 py-1 text-xs font-medium text-gray-500 text-left w-full">
                      <span>{{ column?.field }} </span>
                    </th>
                  </template>
                </template>
              </thead>
              <tbody>
                <template v-if="json.length > 0">
                  <tr class="hover:bg-gray-200" v-for="item in json" :key="item">
                    <template v-for="column in columns" :key="column.field">
                        <td class="px-2 whitespace-nowrap">
                          <slot :name="column.field" :item="item">
                            <div class="text-sm text-gray-900">{{ item[column.field] }}</div>
                          </slot>
                        </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <div class="modal-action">
          <button class="btn btn-ghost" @click="cancel()">Cancel</button>
          <button class="btn" @click="importAll()" :class="{loading:(state==State.Loading)}">Import</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { State } from '@/common/variables.js'
import { UploadIcon, DownloadIcon } from '@heroicons/vue/outline'
import * as ExcelJS from "exceljs"
import excelToJson from 'convert-excel-to-json'
import {saveAs} from "file-saver"
import { tools } from '@/common/tools'

export default {
  emits: ['json'],
  props: {
    name: String,
    columns: Array,
    type: String
  },
  data () {
    return {
      State,
      state: State.Initial,
      file: null,
      open: false,
      json: []
    }
  },
  components: {
    UploadIcon,
    DownloadIcon,
  },
  methods: {
    async template () {
      const filename = `import-template${(this.name?'-'+tools.toSlug(this.name):'')}`
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "CBIS";
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet("Sheet 1");

      // HEADER
      const header = []
      for (const column of this.columns) {
        if (column?.import == null || column?.import) header.push({
          header: column?.field??column,
          key: column?.field?? tools.toCamelCase(column?.field??column),
          width: column?.width??32
        })
      }
      
      worksheet.autoFilter = {
        from: {
          row: 1,
          column: 1
        },
        to: {
          row: 1,
          column: header.length
        }
      }

      worksheet.columns = header

      const row = worksheet.getRow(1)
      row.eachCell(function(cell) {
        cell.font = { bold: true, color: { argb: '7FFFFFFF' } }
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'000000'},
        }
      });
      
      row.commit()
      // HEADER

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], {type: fileType});

      saveAs(blob, filename + fileExtension);
    },
    openModal(){
      this.removeFile()
      this.open = true
    },
    onChange () {
      this.file = this.$refs.file.files ? this.$refs.file.files[0] : null
      this.processFile()
    },
    dragOver (event) {
      event.preventDefault()
    },
    dropFile (event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
    },
    cancel () {
      this.json = []
      this.removeFile()
    },
    removeFile () {
      try {
        this.file = null
        if (this.$refs.file) this.$refs.file.value = null
      } catch (error) {
        this.$toast.error(error)
      }
    },
    processFile () {
      tools.getBase64(this.file).then(function (data) {
        try {
          const columnToKey = {}
          var lastKey = ''
          for (const col of this.columns) {
            if (col?.import == null || col?.import) {
              lastKey = tools.incrementString(lastKey)
              columnToKey[lastKey] = col?.field
            }
          }
          const json = excelToJson({
            source: data,
            header:{
              rows: 1
            },
            columnToKey: columnToKey
          })
          this.json = json['Sheet 1']
          if (this.json.length == 0) this.$toast.error('No data found. Make sure you are using the template. Thank you!')
        } catch (error) {
          this.$toast.error(error)
        }
      }.bind(this), () => {
        this.$toast.error('Failed to convert file')
      })
    },
    importAll () {
      this.open = false
      this.removeFile()
      this.$emit('json', this.json)
      this.json = []
    }
  }
}
</script>