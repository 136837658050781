<template>
  <Container>
    <div class="flex flex-col md:block md:masonry-2-col xl:masonry-3-col box-border before:box-inherit after:box-inherit">
      <div class="break-inside pb-1 mb-4 order-3">
        <Forms/>
      </div>
      <div class="break-inside pb-1 mb-4 order-1">
        <Uploads/>
      </div>
      <div class="break-inside pb-1 mb-4 order-2">
        <Notification/>
      </div>
    </div>
  </Container>
</template>

<script>
// import Parse from 'parse'
import Notification from './components/Notification.vue'
import Uploads from '@/views/hei/components/Uploads.vue'
import Forms from './components/Forms.vue'

export default {
  components: {
    Notification,
    Uploads,
    Forms
  },
  data () {
    return {
      subscription: null
    }
  },
  mounted: async () => {
    // let query = new Parse.Query('Comment')
    // let subscription = await query.subscribe()

    // subscription.on('open', (object) => {
    //   console.log('object entered', object)
    // });

    // subscription.on('close', (object) => {
    //   console.log('object closed', object)
    // });

    // subscription.on('create', (object) => {
    //   console.log(object.get('message'));
    // });


  },
  unmounted: async () => {
    // await this.subscription.unsubscribe();
    // Parse.LiveQuery.close()
  }
}
</script>