import { authGuard } from '@/helpers/AuthGuard.js'
import DashboardHei from '@/views/hei/dashboard/Dashboard.vue'
import DashboardAdmin from '@/views/admin/dashboard/Dashboard.vue'
import DashboardChed from '@/views/ched/dashboard/Dashboard.vue'
import DashboardViewOnly from '@/views/viewOnly/dashboard/Dashboard.vue'

const dashboard = [
  {
    path: '/',
    name: 'Dashboard',
    components: {
      admin: DashboardAdmin,
      ched: DashboardChed,
      hei: DashboardHei,
      viewOnly: DashboardViewOnly
    },
    beforeEnter: authGuard
  },
]

export default dashboard