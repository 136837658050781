<template>
  <div class="px-6 py-4 flex justify-end space-x-3 no-print">
    <div v-if="settings?.hasSearch && items" class="form-control mr-auto">
      <div class="input-group">
        <input type="text" placeholder="Search…" class="input input-bordered" @change="search()" v-model="filters.keyword"/>
        <button class="btn btn-square" @click="search()"><SearchIcon class="h-5 w-5"/></button>
      </div>
    </div>
    
    <BtnExcel
      v-if="settings?.hasExcel && items"
      :columns="columns"
      :items="items"
      :name="name"
      :type="type"
    />
    <BtnImport
      v-if="settings?.hasImport"
      :columns="columns"
      :name="name"
      :type="type"
      @json="json"
    />
    <slot name="buttons"></slot>
    <!-- <button class="btn" @click="exportToExcel()">Export</button> -->
    
  </div>
  <perfect-scrollbar class="h-full">
    <table class="divide-y divide-gray-200 mb-3 border-b w-full" @click="hideContextMenu()">
      <THead
        :settings="settings"
        :columns="columns"
        @sort="sort"
        @checkbox="checkAll"
      />
      <tbody class="bg-white divide-y divide-gray-200">
        <slot name="body">
          <template v-if="items.length > 0">
            <tr class="hover:bg-gray-200" v-for="item in items" :key="tools.listKey(item)" @contextmenu.prevent="contextMenu($event, item)">
              <td v-if="settings?.hasCheckbox" class="whitespace-nowrap no-print">
                <div class="flex items-center justify-center">
                  <input type="checkbox" :value="item._id??item.id" v-model="selected" @change="check()" class="checkbox checkbox-sm">
                </div>
              </td>
              
              <!-- COLUMNS -->
              <template v-for="column in columns" :key="column.field">
                  <td v-if="column?.table == null || column?.table" class="px-6 whitespace-nowrap w-16">
                    <slot :name="column.field" :item="item" :id="item._id??item?.id??item.objectId">
                      <div class="text-sm text-gray-900">{{ (type=='parse'? item.get(column.field) : item[column.field])??column.default }}</div>
                    </slot>
                  </td>
              </template>
              <!-- COLUMNS -->

              <td v-if="settings?.hasOption" class="px-6 whitespace-nowrap w-1">
                <div class="text-sm text-gray-900 flex">
                  <slot name="option" :item="item" :id="item._id??item?.id??item.objectId"></slot>
                </div>
              </td>
            </tr>
            
            <tr v-if="settings?.footerTotal">
              <td v-if="settings?.hasCheckbox" class="no-print"></td>
              <template v-for="column in columns" :key="column.field">
                <td v-if="column?.table == null || column?.table" class="px-6 whitespace-nowrap w-16">
                  <slot v-if="column?.total" name="total" :value="items.reduce((n, item) => n + ((type=='parse'? item.get(column.field) : item[column.field])??column.default), 0)">
                    <div class="text-sm text-gray-900">{{ items.reduce((n, item) => n + ((type=='parse'? item.get(column.field) : item[column.field])??column.default), 0) }}</div>
                  </slot>
                </td>
              </template>
              <td v-if="settings?.hasOption" class="no-print"></td>
            </tr>
          </template>
        </slot>
        
        <tr v-if="state == State.Loading">
          <td :colspan="columns.length + (settings?.hasCheckbox??0) + (settings?.hasOption??0)" class="text-center">Loading</td>
        </tr>
        <tr v-else-if="items.length==0">
          <td :colspan="columns.length + (settings?.hasCheckbox??0) + (settings?.hasOption??0)" class="text-center">No Results</td>
        </tr>
      </tbody>
    </table>
  </perfect-scrollbar>
  <div v-if="pages > 0" class="px-6 py-1 flex justify-end space-x-3 no-print">
    <!-- <div v-if="total" class="mr-auto">
      <span class="text-sm"> {{ total }} results </span>
    </div> -->
    <div v-if="type!='parse'" class="mr-auto">
      <span class="hidden md:inline-block"> {{ filters.limit }} rows per page </span>
      <div class="dropdown dropdown-top ml-2">
        <label tabindex="0" class="btn btn-sm">{{ filters.limit??total }}</label>
        <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
          <li><a @click="setRows(null)">No Limit</a></li>
          <li v-if="total>1000"><a @click="setRows(1000)">1000</a></li>
          <li v-if="total>500"><a @click="setRows(500)">500</a></li>
          <li v-if="total>250"><a @click="setRows(250)">250</a></li>
          <li v-if="total>100"><a @click="setRows(100)">100</a></li>
        </ul>
      </div>
      <span class="ml-2"> {{filters.page}}/{{pages}} </span>
    </div>
    <div v-if="pagination.show && pages > 1" class="btn-group">
      <button class="btn btn-sm" @click="prev()"><ChevronLeftIcon class="w-5 h-5"/></button>
      <button v-for="btn in buttons" :key="btn" class="btn btn-sm hidden md:block" :class="{'btn-active':(filters.page==btn)}" @click="jumpTo(btn)">{{btn}}</button>
      <button class="btn btn-sm" @click="next()"><ChevronRightIcon class="w-5 h-5"/></button>
    </div>
  </div>
  <div v-if="settings?.hasContextMenu" ref="contextmenu" class="fixed dropdown dropdown-open top-3" :class="{hidden:!contextmenu}">
    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
      <slot name="contextmenu" :item="contextmenu"></slot>
    </ul>
  </div>
</template>

<script>
import { State } from '@/common/variables'
import { tools } from '@/common/tools'
import { SearchIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'
import { ceil } from 'lodash'
import BtnExcel from '@/components/button/excel.vue'
import BtnImport from '@/components/button/import.vue'

export default {
  emits: ['filters','checkbox','json', 'selected'],
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      default () {
        return {hasSearch: true}
      }
    },
    columns: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    // paginate: Boolean,
    state: {
      type: Number,
      default: State.Initial
    },
    total: Number,
    rows: Number,
    type: {
      type: String,
      default: 'parse'
    }
  },
  mounted () {
    this.setup()
  },
  watch: {
    total () {
      this.filters.page = 1
      this.setup()
    },
  },
  data () {
    return {
      tools,
      State,
      contextmenu: false,
      pagination: {
        show: true
      },
      selected: [],
      pages: 0,
      buttons: [],
      paginateStart: 1,
      paginateLength: 5,
      filters: {
        keyword: '',
        sort: null,
        page: 1,
        skip: 0,
        limit: 100
      }
    }
  },
  methods: {
    hideContextMenu () {
      this.contextmenu = null
    },
    contextMenu (event, item) {
      try {
        this.contextmenu = item
        this.$refs.contextmenu.style.top = `${event.clientY}px`
        this.$refs.contextmenu.style.left = `${event.clientX}px`
      } catch (error) {
        this.contextmenu = null
      }
    },
    setup () {
      if (this.filters.limit) {
        this.pages = ceil(this.total/this.filters.limit)
      } else {
        this.pages = 1
      }
      

      if (this.filters.page < 3) {
        this.paginateStart = 1
      } else if (this.filters.page > (this.pages-5) && this.pages > 5) {
        this.paginateStart = this.pages - 4
      } else {
        this.paginateStart = this.filters.page - 2
      }

      this.paginateLength = this.paginateStart + (this.pages > 5 ? 4 : (this.pages-1))
      this.buttons = []
      for (let index = this.paginateStart; index <= this.paginateLength; index++) {
        this.buttons.push(index)
      }
    },
    sort (data) {
      this.filters.sort = data
      this.$emit('filters', this.filters)
    },
    search () {
      this.$emit('filters', this.filters)
    },
    checkAll (data) {
      this.$emit('checkbox', data)
      this.selected = []
      for (var checkbox of this.items) {
        if (data) {
          this.selected.push(checkbox.id??checkbox._id)
        } else {
          this.selected = []
        }
      }
      this.$emit('selected', this.selected)
    },
    check () {
      this.$emit('selected', this.selected)
    },
    next () {
      if (this.pages > this.filters.page) this.filters.page++
      this.filters.skip = (this.filters.page-1) * this.filters.limit
      this.$emit('filters', this.filters)
      this.setup()
    },
    prev () {
      if (1 < this.filters.page) this.filters.page--
      this.filters.skip = (this.filters.page-1) * this.filters.limit
      this.$emit('filters', this.filters)
      this.setup()
    },
    jumpTo (index) {
      if (1 <= index && this.pages >= index) this.filters.page = index
      this.filters.skip = (this.filters.page-1) * this.filters.limit
      this.$emit('filters', this.filters)
      this.setup()
    },
    setRows (rows) {
      this.filters.limit = rows
      this.$emit('filters', this.filters)
      this.setup()
    },
    json (json) {
      this.$emit('json', json)
    }
  },
  components: {
    BtnImport,
    BtnExcel,
    SearchIcon,
    ChevronLeftIcon,
    ChevronRightIcon
  }
}
</script>