<template>
  <button class="btn btn-ghost hidden xl:inline-flex" @click="excel()"><DocumentDownloadIcon class="h-5 w-5 mr-2"/>Excel</button>
</template>

<script>
import { DocumentDownloadIcon } from '@heroicons/vue/outline'
import * as ExcelJS from "exceljs"
import {saveAs} from "file-saver"
import { tools } from '@/common/tools'

export default {
  props: {
    name: String,
    columns: Array,
    items: Array
  },
  components: {
    DocumentDownloadIcon
  },
  methods: {
    async excel () {
      const filename = this.name?tools.toSlug(this.name):'export'
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "CBIS";
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet("Sheet 1");

      // HEADER
      const header = []
      for (const column of this.columns) {
        if (column?.excel == null || column?.excel) header.push({
          header: column?.name??column,
          key: column?.field?? tools.toCamelCase(column?.name??column),
          width: column?.width??32
        })
        console.log(column?.excel == null)
      }
      

      worksheet.autoFilter = {
        from: {
          row: 1,
          column: 1
        },
        to: {
          row: 1,
          column: header.length
        }
      }

      worksheet.columns = header

      const row = worksheet.getRow(1)
      row.eachCell(function(cell) {
        cell.font = { bold: true, color: { argb: '7FFFFFFF' } }
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'000000'},
        }
      });
      
      row.commit()
      // HEADER

      for (const row of this.items) {
        const toAdd = []
        for (let index = 0; index < header.length; index++) {
          const col = header[index]
          toAdd[index] = row[col.key]
        }
        worksheet.addRow(toAdd)
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], {type: fileType});

      saveAs(blob, filename + fileExtension);
    },
  }
}
</script>