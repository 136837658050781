<template>
  <Container>
    <div class="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-3 grid-rows-none gap-2 pb-2">
      ched dashboard
    </div>
  </Container>
</template>

<script>

export default {
  components: {
  },
  mounted () {
  }
}
</script>