
const updateQuery = (cx, key, data) => {
  const q = Object.assign({}, cx.$route.query)
  switch (typeof data) {
    case 'object':
      q[key] = JSON.stringify(data)
      break;
    default:
      q[key] = data
      break;
  }
  cx.$router.push({query: q })
}

const createQuery = (cx, key, data) => {
  const q = Object.assign({}, cx.$route.query)
  switch (typeof data) {
    case 'object':
      q[key] = JSON.stringify(data)
      break;
    default:
      q[key] = data
      break;
  }
  return {query: q }
}

export { updateQuery, createQuery }