<template>
  <div class="min-h-screen relative">
    <div
      class="bg-base-200 drawer relative"
      :class="{ 'drawer-mobile': !hiddenSidebar }"
    >
      <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
      <div class="flex flex-col drawer-content relative">
        <navbar v-if="!hideFrom.includes($route.name)" />
        <!-- OFFLINE MODE -->
        <div
          v-if="$store.state.offline"
          class="w-full bg-gray-800 text-center text-white p-1 text-sm"
        >
          You are currently using the app offline.
          <div class="dropdown dropdown-end">
            <span tabindex="0" class="text-yellow-400 cursor-pointer"
              >Click Here.</span
            >
            <div
              tabindex="0"
              class="shadow rounded-md card compact dropdown-content bg-base-100 w-52 text-gray-800 text-left"
            >
              <div class="card-body">
                <h2 class="card-title">App is Offline</h2>
                <p>
                  You will be limited to few features of the app. Try to
                  reconnect to your internet and click refresh.
                </p>
                <button
                  class="btn mt-3"
                  @click="$store.commit('offline', false)"
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- OFFLINE MODE -->
        <!-- UPDATE -->
        <div v-if="update.state" class="alert bg-white rounded-none">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="stroke-info flex-shrink-0 w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <div>
              <h3 class="font-bold">New update available!</h3>
              <div class="text-xs">
                You will be upgraded to v{{ update.version }}
              </div>
            </div>
          </div>
          <div class="flex-none">
            <button @click="update.state = false" class="btn btn-sm btn-ghost">
              Cancel
            </button>
            <a
              :href="'?version=' + update.version"
              class="btn btn-sm btn-primary"
              >Update</a
            >
          </div>
        </div>
        <!-- UPDATE -->
        <router-view />
        <router-view :name="role" />
      </div>
      <sidebar
        v-if="!hideFrom.includes($route.name)"
        class="shadow min-h-screen no-print"
        :version="version"
      />
    </div>
  </div>
  <NotificationPrompt />
</template>

<script>
import Parse from "parse";
Parse.serverURL = "https://api.cbis.chedro5.com/api/";
Parse.initialize(
  "A9YOG0dz7u17HhTUWyhqascUt7V8Wr",
  "DCpkz9*oIQSr@z2N1ni5ixam2hbrW3aRSxVO46xKfwv02"
);
Parse.enableEncryptedUser();
Parse.secret = "aJrRtF6dsMHTnXKD0TnCgzyNuXDoDLqWk0pIhdi6";
Parse.enableLocalDatastore();

// Parse.CoreManager.setStorageController(Parse.IndexedDB)

import { user, ErrorHandler } from "@/parse/user";
import NotificationPrompt from "@/components/NotificationPrompt";
import { config } from "@/parse/config";
import { version } from "../package";

export default {
  data() {
    return {
      hideFrom: [
        "Test",
        "Sign Up",
        "Sign In",
        "404",
        "Session Invalid",
        "Forgot Password",
        "Verify Email",
        "Under Maintenance",
        "Spreadsheet",
      ],
      user: null,
      role: null,
      hiddenSidebar: false,
      version,
      update: {
        state: false,
        version: "",
      },
    };
  },
  components: {
    NotificationPrompt,
  },
  computed: {
    userChanged() {
      return this.$store.state.userChanged;
    },
  },
  watch: {
    userChanged(newValue) {
      if (newValue) {
        // console.log('changed')
        this.$store.commit("userUpdated");
        user.getRole().then(
          function (role) {
            role = role?.get("name");
            if (role == "CHED") {
              this.role = "ched";
            } else if (role == "CHED Admin") {
              this.role = "admin";
            } else if (role == "View Only") {
              this.role = "viewOnly";
            } else {
              this.role = "hei";
            }
            this.$store.commit("setRole", this.role);
          }.bind(this),
          (error) => {
            ErrorHandler(error);
          }
        );
      }
    },
  },
  mounted() {
    this.hiddenSidebar = localStorage.getItem("hiddenSidebar");

    Parse.User.current()
      ?.fetch()
      .then(
        function (user) {
          this.user = user;
        }.bind(this)
      );
    this.user = Parse.User.current();

    if (this.user != null) {
      this.$store.commit("signIn");
    } else {
      this.$store.commit("signOut");
    }

    window.addEventListener(
      "offline",
      function () {
        this.$store.commit("offline", true);
      }.bind(this)
    );

    user.getRole().then(
      function (role) {
        role = role?.get("name");
        if (role == "CHED") {
          this.role = "ched";
        } else if (role == "CHED Admin") {
          this.role = "admin";
        } else if (role == "View Only") {
          this.role = "viewOnly";
        } else {
          this.role = "hei";
        }
        this.$store.commit("setRole", this.role);
      }.bind(this),
      (error) => {
        ErrorHandler(error);
      }
    );

    config.get().then(
      function (data) {
        if (version < data.get("version")) {
          this.update = {
            state: true,
            version: data.get("version"),
          };
        }
      }.bind(this)
    );
  },
};
</script>