<template> 
  <div class="shadow sm:rounded bg-white break-inside">
    <div class="w-full p-4 flex items-center justify-between ">
      <p class="text-gray-800 dark:text-white text-xl">
        Uploads
      </p>
      <router-link to="/upload/files" class="flex items-center text-sm hover:text-gray-600 dark:text-gray-50 dark:hover:text-white text-gray-300 border-0 focus:outline-none">
        View All
      </router-link>
    </div>
    <ul v-if="state==State.Done">
      <template v-for="(form, index) in forms" :key="index">
        <li class="flex items-center  justify-between py-3 border-b-2  last:border-b-0 border-gray-100" :class="[form.get('approved') ? 'text-gray-400' : 'text-gray-600']">
          <div class="flex items-center justify-start text-sm">
            <span class="mx-4">
              {{ String(index+1).padStart(2, '0') }}
            </span>
            <span v-if="form.get('approved')" class="line-through">
              {{ form.get('form').get('name') }}
            </span>
            <router-link v-else :to="{ name: form.get('form').get('pathName'), params: { id:form.id } }" class="hover:underline">
              {{ form.get('form').get('name') }}
            </router-link>
            <!-- <span class="lg:ml-6 ml-2 flex items-center text-gray-400 dark:text-gray-300">
              3 <ChatIcon class="ml-1 h-4 w-4"/>
            </span> -->
          </div>
          <div v-if="form.get('approved')" data-tip="Accepted" class="tooltip tooltip-left mx-4">
            <CheckDone class="h-5 w-5 text-green-400"/>
          </div>
          <div v-else-if="form.get('notice')" data-tip="Notice" class="tooltip tooltip-left mx-4">
            <ExclamationCircleIcon class="h-5 w-5 text-red-400"/>
          </div>
          <div v-else-if="form.get('file')" data-tip="Uploaded" class="tooltip tooltip-left mx-4">
            <CheckMute class="h-5 w-5 text-gray-400"/>
          </div>
          <div v-else data-tip="No Upload" class="tooltip tooltip-left mx-4">
            <MinusCircleIcon class="h-5 w-5 text-gray-300"/>
          </div>
        </li>
      </template>
    </ul>
    <div v-if="state==State.Loading" class="h-72 flex items-center justify-center animate-pulse"></div>
    <div v-if="state==State.Empty" class="h-72 flex items-center justify-center">
      <span class="text-gray-600">No File Request from CHED</span>
    </div>
  </div>
</template>

<script>
import { State } from '@/common/variables'
import { CheckCircleIcon as CheckMute, MinusCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline'
import { CheckCircleIcon as CheckDone } from '@heroicons/vue/solid'
import { file } from '@/parse/file'

export default {
  data () {
    return {
      State,
      state: State.Loading,
      forms: null,
      ayId: null
    }
  },
  components: {
    CheckMute,
    CheckDone,
    // ChatIcon,
    MinusCircleIcon,
    ExclamationCircleIcon
  },
  mounted () {
    this.ayId = this.$store.state.academicYearId
    this.search()
  },
  methods: {
    search () {
      file.find({
        academicYearId: this.ayId
      }).then(function (forms) {
        // console.log(forms)
        this.forms = forms
        
        if(forms.length>0){
          this.state = State.Done
        }else{
          this.state = State.Empty
        }
      }.bind(this))
    }
  },
  computed: {
    academicYearId () {
      return this.$store.state.academicYearId
    }
  },
  watch: {
    academicYearId (newValue) {
      if (newValue) {
        this.ayId = newValue
        this.search()
      }
    }
  }
}
</script>