<template>
  <button class="btn btn-ghost hidden xl:inline-flex" @click="print()"><PrinterIcon class="h-5 w-5 mr-2"/>Print</button>
</template>

<script>
import { PrinterIcon } from '@heroicons/vue/outline'

export default {
  components: {
    PrinterIcon
  },
  methods: {
    print () {
      window.print()
    },
  }
}
</script>