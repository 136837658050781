<template>
  <Container v-if="uploads">
    <div class="flex flex-col md:block md:masonry-2-col xl:masonry-3-col box-border before:box-inherit after:box-inherit">
      <div class="break-inside pb-4">
        <OverallCard
          :uploads="uploads"
        />
      </div>
      <div class="break-inside pb-4">
        <ProgressCard
          name="LUCS Upload Progress"
          :uploads="uploads.lucs"
          :total="uploads.total.lucs"
        />
      </div>
      <div class="break-inside pb-4">
        <ProgressCard
          name="SUCS Upload Progress"
          :uploads="uploads.sucs"
          :total="uploads.total.sucs"
        />
      </div>
      <div class="break-inside pb-4">
        <ProgressCard
          name="Private Upload Progress"
          :uploads="uploads.private"
          :total="uploads.total.private"
        />
      </div>
    </div>
  </Container>
  <!-- <button @click="requestFiles()">SS</button> -->
</template>

<script>
import { admin } from '@/parse/admin'
import ProgressCard from './ProgressCard.vue'
import OverallCard from './overall-card.vue'

export default {
  data () {
    return {
      requesting: false,
      uploads: null
    }
  },
  components: {
    ProgressCard,
    OverallCard
  },
  mounted () {
    // var date1 = new Date()
    // var date2 = new Date()

    // console.log(date1)
    // console.log(date2)
    // console.log(date1.getTime() === date2.getTime())

    this.getUploadSummary()
  },
  methods: {
    getUploadSummary () {
      admin.getUploadSummary({
        academicYearId: this.academicYearId
      }).then(function (data) {
        // console.log(data)
        this.uploads = data
      }.bind(this), (error) => {
        // console.error(error)
        this.$toast.error(error.message)
      })
    }
  },
  computed: {
    academicYearId () {
      return this.$store.state.academicYearId
    }
  },
  watch: {
    academicYearId (newValue) {
      if (newValue) {
        this.getUploadSummary()
      }
    }
  }
}
</script>