import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyAGyaSQ-7Dp4K0P0TmkfnGwT1WNsHjx7hI",
  authDomain: "ched-49aa9.firebaseapp.com",
  projectId: "ched-49aa9",
  storageBucket: "ched-49aa9.appspot.com",
  messagingSenderId: "686517235307",
  appId: "1:686517235307:web:3dde54aa72ac80f4008b2e"
};

firebase.initializeApp(firebaseConfig)
let messaging = null
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}

export default messaging
