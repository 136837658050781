<template>
  <thead class="bg-gray-50 sticky top-0 z-10">
    <tr>
      <th v-if="settings?.hasCheckbox" scope="col" class="px-6 no-print p-0 w-1/12">
        <input type="checkbox" class="checkbox checkbox-sm" @click="check()" v-model="checkbox"/>
      </th>
      <template v-for="(column, index) in columns" :key="column">
        <template v-if="column?.table == null || column?.table">
          <th v-if="column?.static" scope="col" class="px-6 py-3 cursor-pointer text-xs font-medium text-gray-500 uppercase text-left" :class="{'w-full': index==(columns.length-1)}">
            <span :class="{'sr-only':settings?.screenReaderOnly}">{{ column?.name??column }} </span>
          </th>
          <th v-else @click="sort(column, index)" scope="col" class="px-6 py-3 cursor-pointer text-xs font-medium text-gray-500 uppercase text-left" :class="{'w-full': index==(columns.length-1)}">
            <div  class="flex items-center">
              <span class=" whitespace-nowrap">{{ column?.name??column }}</span>
              <template v-if="active == index">
                <ChevronUpIcon v-if="ascending" class="w-3 h-3 ml-1 flex-shrink-0"/>
                <ChevronDownIcon v-else class="w-3 h-3 ml-1 flex-shrink-0"/>
              </template>
            </div>
          </th>
        </template>
      </template>
      <th v-if="settings?.hasOption" scope="col" class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase no-print w-1/12">
        Option
      </th>
    </tr>
  </thead>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'
import { tools } from '@/common/tools'
import { updateQuery } from './table'

export default {
  emits: ['sort', 'checkbox'],
  props: {
    settings: Object,
    columns: Array
  },
  components: {
    ChevronDownIcon,
    ChevronUpIcon
  },
  data () {
    return {
      checkbox: false,
      ascending: false,
      active: -1
    }
  },
  methods: {
    sort (column, index) {
      this.active = index
      this.ascending = !this.ascending
      const columnName = column?.field?? tools.toCamelCase(column?.name??column)
      const data = {}
      data[columnName] = this.ascending?1:-1
      this.$emit('sort', data)
      
      updateQuery(this, 'sort', data)
    },
    check () {
      this.checkbox = !this.checkbox
      this.$emit('checkbox', this.checkbox)
    }
  }
}
</script>