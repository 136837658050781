import Parse from 'parse'

var admin = {
  requestFiles ( args = {} ) {
    console.log(args)
    // const { academicYearId } = args
    return Parse.Cloud.run('requestFiles', args)
  },
  getUploads () {
    return Parse.Cloud.run('adminGetUploads')
  },
  getUploadSummary (args = {}) {
    return Parse.Cloud.run('getUploadSummary', args)
  }
}

export { admin }