<template>
  <div class="drawer-side" :class="{'lg:z-10':!hiddenSidebar}">
    <label for="my-drawer-2" class="drawer-overlay"></label>
    <div class="flex flex-col flex-shrink-0 text-gray-700 bg-white w-80">
      <div class="flex flex-col items-center flex-shrink-0 pb-4 pt-8">
        <Avatar class="w-20 h-20 mb-8" :source="user.avatar"/>
        <h2 class="text-sm font-medium tracking-widest text-black uppercase title-font"> {{ user.name }} </h2>
        <p class="text-gray-500 text-center truncate overflow-ellipsis w-80 px-14 h-6"> {{ role }} </p>
        <p class="text-gray-500 text-center text-xs truncate overflow-ellipsis w-80 px-14 h-6"> v{{ version }} </p>
      </div>
      <nav class="flex-grow pb-4 pr-4 md:block md:pb-0 md:overflow-y-auto">
        <ul>
          <li v-for="(item, index) in baseNav" :key="index">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
          <!-- <li>
            <router-link to="/records">Records</router-link>
          </li> -->
          <!-- <li>
            <router-link to="/upload">Upload</router-link>
          </li> -->
          <li>
            <router-link to="/settings">Settings</router-link>
          </li>
          <li>
            <router-link to="/account">Account</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { institution } from '@/parse/institution'
import { user, ErrorHandler } from '@/parse/user'

var baseNav = [
  {
    name: 'Dashboard',
    path: '/'
  },
  // {
  //   name: 'Forum',
  //   path: '/forum'
  // },
  {
    name: 'Notifications',
    path: '/notifications'
  },
  {
    name: 'References',
    path: '/references'
  },

]

var chedNav = [
  {
    name: 'Monitoring',
    path: '/monitoring'
  },
]

var viewOnlyNav = [
  {
    name: 'Records',
    path: '/records'
  },
]

var adminNav = [
  {
    name: 'Comments',
    path: '/comments'
  },
  {
    name: 'Data Collection',
    path: '/data-collection'
  },
  
  {
    name: 'Programs',
    path: '/programs'
  },
  {
    name: 'Records',
    path: '/records'
  },
  {
    name: 'Uploads',
    path: '/uploads'
  },
  {
    name: 'Users',
    path: '/users'
  },
]

var heiNav = [
  {
    name: 'Upload',
    path: '/upload'
  },
  {
    name: 'Data Collection',
    path: '/data-collection'
  },
]

export default {
  props: {
    version: String
  },
  data () {
    return {
      update: {
        state: false,
        version: ''
      },
      baseNav,
      chedNav,
      adminNav,
      heiNav,
      viewOnlyNav,
      user: {
        name: null,
        avatar: null
      },
      role: null,
      hiddenSidebar: false
    }
  },
  computed: {
    profileChanged () {
      return this.$store.state.profileChanged
    }
  },
  watch: {
    profileChanged (newValue) {
      if(newValue){
        this.$store.commit('profileUpdated')
        this.populate()
      }
    }
  },
  methods: {
    populate () {
      const u = user.current()
      this.user.name = u?.get('fname')??u?.get('username')
      this.user.avatar = u?.get('avatar')?.url()
      user.getRole().then(function (data) {
        let role = data?.get('name')
        if(role == 'CHED') {
          this.baseNav = this.baseNav.concat(this.chedNav)
          this.role = role
        }else if(role == 'CHED Admin') {
          this.baseNav = this.baseNav.concat(this.chedNav).concat(this.adminNav)
          this.role = role
        }else if(role == 'View Only') {
          this.baseNav = this.baseNav.concat(this.viewOnlyNav)
          this.role = role
        }else{
          this.baseNav = this.baseNav.concat(this.heiNav)
          institution.first().then(function(data){
            this.role = data.get('name')
          }.bind(this))
        }
      }.bind(this), (error)=>{
        ErrorHandler(error)
      })
    }
  },
  mounted () {
    this.hiddenSidebar = localStorage.getItem('hiddenSidebar')
    this.populate()
    // this.profileChanged ()
    // console.log(version)
    
  }
}
</script>