import { authGuard } from '@/helpers/AuthGuard.js'

const forum = [
  {
    path: '/forum',
    components: {
      hei: () => import ('@/views/forum/hei'),
      admin: () => import ('@/views/forum/admin'),
    },
    beforeEnter: authGuard
  },
]

export default forum