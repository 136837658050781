<template>
  <div class="shadow rounded px-4 py-6 w-full bg-white  relative">
    <p class="text-sm w-max text-gray-700 font-semibold border-b border-gray-200">
      Complied HEIs
    </p>
    <div class="flex items-end space-x-2 my-6">
      <p class="text-5xl text-black font-bold">
        {{ uploads.overall.withUploads }}
      </p>
      <span class="text-red-500 text-xl font-bold flex items-center" :class="{'text-green-500':percent>=80}">
        {{ percent.toFixed(2) }}%
        <ArrowUpIcon v-if="percent>=80" class="w-4 h-4"/>
        <ArrowDownIcon v-else class="w-4 h-4"/>
      </span>
    </div>
    <div class="dark:text-white">
      <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200 mb-2 pb-2 last:border-b-0 last:mb-0 last:pb-0">
        <p>
          Total HEIs
        </p>
        <div class="flex items-end text-xs">
          {{ uploads.overall.total }}
        </div>
      </div>
      <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200 mb-2 pb-2 last:border-b-0 last:mb-0 last:pb-0">
        <p>
          No Uploads
        </p>
        <div class="flex items-end text-xs">
          {{ uploads.overall.total - uploads.overall.withUploads }}
        </div>
      </div>
      <div v-if="percent<98" class="flex items-center text-sm space-x-12 md:space-x-24 justify-between border-b-0 mb-0 pb-0">
        <div class="alert">
          <div class="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2196f3" class="w-6 h-6 mx-2">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
            </svg> 
            <label v-if="percent<80">{{ remaining(80) + ' remaining HEIs to reach over 80% or ' + remaining(90) + ' HEIs to reach over 90%' }}</label>
            <label v-else-if="percent<90">{{ remaining(90) + ' remaining HEIs to reach over 90%' }}</label>
            <label v-else-if="percent<95">{{ remaining(95) + ' remaining HEIs to reach over 95%' }}</label>
            <label v-else>{{ remaining(98) + ' remaining HEIs to reach over 95%' }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/outline'

export default {
  props: {
    uploads: Object
  },
  components: {
    ArrowUpIcon,
    ArrowDownIcon
  },
  computed: {
    percent () {
      return (this.uploads.overall.withUploads/this.uploads.overall.total)*100
    }
  },
  methods: {
    remaining (percent) {
      return Math.round(percent / (100/this.uploads.overall.total)) - this.uploads.overall.withUploads
    }
  }
}
</script>