<template>
  <div class="avatar flex-shrink-0">
    <div class="rounded-full w-full h-full bg-gray-300">
      <img v-if="source" :src="source" @error="this.target.src = fallback">
      <img v-else :src="fallback??defaultImg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: String,
    fallback: String
  },
  data () {
    return {
      defaultImg: '/img/avatar.jpg'
    }
  }
}
</script>