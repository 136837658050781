import Parse from 'parse'

const Institution = Parse.Object.extend('Institution')
const FormOfOwnership = Parse.Object.extend('FormOfOwnership')

var institution = {
  all: function ( args = {} ) {
    const { provinces } = args
    const q = new Parse.Query(Institution)
    q.include('formOfOwnership')
    q.equalTo('LF2F', true)
    q.containedIn('province', provinces)
    // q.ascending('province')
    return q.findAll()
  },
  pointer: function (id) {
    const query = new Institution()
    query.id = id
    return query
  },
  get: function ( args = {} ) {
    const { id } = args
    if ( !id ) throw {message:'ID is required'}
    const q = new Parse.Query(Institution)
    return q.get(id)
  },
  first: function () {
    const q = new Parse.Query(Institution)
    q.include('formOfOwnership')
    return q.first()
  },
  save: async function ( args = {} ) {
    const { id, name, isActive, ownership, otherFormOfOwnership, website, code, yearEstablished, yearGranted, yearCollege, yearUniversity } = args
    let formOfOwnership = ownership
    console.log('ownership', ownership)
    if(otherFormOfOwnership && ownership == -1){
      const newFOO = new FormOfOwnership()
      newFOO.set('name', otherFormOfOwnership)
      newFOO.set('code', 'OT')
      formOfOwnership = await newFOO.save()
      console.log('new FOO',formOfOwnership)
    }

    const object = new Institution()
    object.id = id
    object.set('name', name)
    object.set('isActive', isActive)
    object.set('formOfOwnership', formOfOwnership)
    object.set('website', website)
    object.set('code', code)
    object.set('yearEstablished', parseInt(yearEstablished))
    object.set('yearGranted', parseInt(yearGranted))
    object.set('yearCollege', parseInt(yearCollege))
    object.set('yearUniversity', parseInt(yearUniversity))
    return object.save()
  },
  simpleSave: async function ( args = {} ) {
    const { name, ownership, code, yearEstablished, yearGranted, yearCollege, yearUniversity } = args

    const object = new Institution()
    object.set('name', name)
    object.set('formOfOwnership', ownership)
    object.set('code', code)
    object.set('yearEstablished', parseInt(yearEstablished))
    object.set('yearGranted', parseInt(yearGranted))
    object.set('yearCollege', parseInt(yearCollege))
    object.set('yearUniversity', parseInt(yearUniversity))
    return object.save()
  } 
}

export { Institution, institution }