<template>
  <section class="flex flex-col items-center h-screen md:flex-row ">
    <div class="hidden w-full h-screen bg-gradient-to-b from-primary to-primary-focus lg:block md:w-1/3 lg:w-2/3">
      <img src="/img/bg.jpg" alt="" class="object-cover w-full h-full opacity-50">
    </div>
    <div class="flex items-center justify-center w-full h-screen px-6 bg-white md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 lg:px-16 xl:px-12">
      <div class="w-full h-100">
        <a class="flex items-center mb-4 font-medium text-blueGray-900 title-font md:mb-0">
          <img src="/img/logo-small.png?new=yes" alt="" class="w-56">
        </a>
        <h1 class="mt-12 text-2xl font-semibold text-black tracking-ringtighter sm:text-3xl title-font">Log in to your account</h1>
        <Form @submit="onSubmit">
          <div class="form-control">
            <label class="label">
              <span class="label-text">Email/Username</span>
            </label>
            <Field name="email" :rules="rules.email" class="input input-bordered"/>
            <ErrorMessage name="email" class="label label-text-alt text-error" />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Password</span>
            </label>
            <div class="relative">
              <Field :type="showPassword?'text':'password'" name="password" :rules="rules.password" class="input input-bordered w-full"/>
              <span class="absolute right-4 mt-4 cursor-pointer" @click="showPassword=!showPassword"><EyeOffIcon v-if="showPassword" class="w-4 h-4"/><EyeIcon v-else class="w-4 h-4"/></span>
            </div>
            <ErrorMessage name="password" class="label label-text-alt text-error" />
          </div>
          <div v-if="form.state == State.Error" class="alert alert-error mt-6">
              <div class="flex-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!---->
              </svg>
              <label>{{ form.message }}</label>
              </div>
          </div>
          <div class="form-control mt-6">
            <button type="submit" class="btn btn-primary" v-bind:class="{ loading: form.state == State.Loading }">Sign in</button>
          </div>
          <div class="mt-4 flex">
            <div class="text-sm">
              <router-link to="/auth/signup" class="font-medium text-gray-800 hover:text-black">
                Sign up here.
              </router-link>
            </div>
            <div class="text-sm ml-auto">
              <router-link to="/auth/forgot-password" class="font-medium text-gray-800 hover:text-black">
                Forgot your password?
              </router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </section>
</template>

<script>
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid'
import { State } from '@/common/variables'
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { user } from '@/parse/user'
import { functions } from '@/parse/functions'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    EyeIcon,
    EyeOffIcon
  },
  data () {
    return {
      State,
      showPassword: false,
      rules: {
        email: yup.string().required(),
        password: yup.string().required().min(6)
      },
      form: {
        state: State.Done,
        message: null
      },
      client: {
        token: null,
        deviceInfo: null,
        ipAddress: null
      }
    }
  },
  mounted () {
    functions.getClientInfo().then((data) => {
      this.client.deviceInfo = data.device
      this.client.ipAddress = data.ip
    })
  },
  methods: {
    onSubmit (values) {
      this.form = {
        state: State.Loading,
        message: null
      }

      console.log(this.client)

      user.login({
        email: values.email,
        pass: values.password
      }).then(() => {
        this.$store.commit('signIn')
        user.updateSession({
          token: null,
          deviceInfo: this.client.deviceInfo,
          ipAddress: this.client.ipAddress
        })
        this.$store.commit('userChanged')
        this.$router.push({ name: 'Dashboard' })
      }).catch(error => {
        this.form = {
          state: State.Error,
          message: error.message
        }
      })
    }
  }
}
</script>
