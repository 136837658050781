import Parse from 'parse'

const Notification = Parse.Object.extend('Notification')

var notification = {
  recent: () => {
    const q = new Parse.Query(Notification)
    q.descending('createdAt')
    q.limit(5)
    return q.find()
  },
  find: () => {
    const q = new Parse.Query(Notification)
    q.descending('createdAt')
    return q.find()
  },
  markAllAsRead: () => {
    return Parse.Cloud.run('readAllNotifications')
  },
  send: ( args = {} ) => {
    // const { title, body, data, tokens } = args
    return Parse.Cloud.run('sendFCMNotification', args)
  },
  sendEmail: (  ) => {
    // const { title, body, data, tokens } = args
    return Parse.Cloud.run('sendEmail', {email:'johnjoshualipio@gmail.com', subject:'test email', body:'hey, just a test', link:'cbis.chedro5.com'})
  },
  sendNotif: (  ) => {
    // const { title, body, data, tokens } = args
    return Parse.Cloud.run('sendRoleNotification', {title:'title test', body:'test', roleId: 'GYocYqo2rN', sendEmail: true})
  },
  test: (  ) => {
    // const { title, body, data, tokens } = args
    // return Parse.Cloud.run('importE2')

    // const mySchema = new Parse.Schema('CollateFaculty')
    // return mySchema.purge()
  },
}

export { notification }